import { Client, cacheExchange, fetchExchange } from '@urql/vue';
import { shopifyFetch } from './client.ts';

const storefrontAccessToken = import.meta.env.VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN;
const shopifyApiUrl = import.meta.env.VITE_SHOPIFY_STOREFRONT_API_URL;

if (!storefrontAccessToken) {
  throw new Error('VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN is not defined');
}

if (!shopifyApiUrl) {
  throw new Error('VITE_SHOPIFY_STOREFRONT_API_URL is not defined');
}

export function getShopifyClient() {
  return new Client({
    url: shopifyApiUrl,
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: () => {
      return {
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
        },
      };
    },
  });
}
