import type { PageContext } from 'vike/types';

import { createHead } from '@unhead/vue';
import MasonryWall from '@yeger/vue-masonry-wall';
import { createPinia } from 'pinia';
import { Head } from 'vike-vue/Head';

import { anchorDirective } from '@/utils/anchor.ts';
import { fadeInDirective } from '@/utils/fadeIn.ts';

import '@/assets/css/index.css';
import '@/assets/css/fonts.css';
import 'overlayscrollbars/overlayscrollbars.css';

export function onCreateApp({ app, isRenderingHead }: PageContext) {
  if (isRenderingHead || !app) return;

  app.use(createHead());
  app.use(createPinia());
  app.use(MasonryWall);

  app.component('Head', Head);

  app.directive('anchor', anchorDirective);
  app.directive('fade-in', fadeInDirective);
}
