import { getProductById, getProductByHandle } from '@/lib/shopify/product';

const preloadedData = new Map<string, any>();

export function useShopifyPreload() {
  const preloadProduct = async (handle: string) => {
    // Don't preload if already preloaded
    if (preloadedData.has(`product:${handle}`)) {
      return;
    }

    try {
      // Start preloading in the background
      const productPromise = getProductByHandle(handle);
      preloadedData.set(`product:${handle}`, productPromise);
    } catch (error) {
      console.error('Error preloading product:', error);
    }
  };

  const preloadProductById = async (id: string) => {
    // Don't preload if already preloaded
    if (preloadedData.has(`product:id:${id}`)) {
      return;
    }

    try {
      // Start preloading in the background
      const productPromise = getProductById(id);
      preloadedData.set(`product:id:${id}`, productPromise);
    } catch (error) {
      console.error('Error preloading product:', error);
    }
  };

  const getPreloadedProduct = async (handle: string) => {
    const cached = preloadedData.get(`product:${handle}`);
    if (cached) {
      // If it's a promise, await it
      const result = await cached;
      // Clear from cache after use
      preloadedData.delete(`product:${handle}`);
      return result;
    }
    return null;
  };

  const getPreloadedProductById = async (id: string) => {
    const cached = preloadedData.get(`product:id:${id}`);
    if (cached) {
      // If it's a promise, await it
      const result = await cached;
      // Clear from cache after use
      preloadedData.delete(`product:id:${id}`);
      return result;
    }
    return null;
  };

  return {
    preloadProduct,
    preloadProductById,
    getPreloadedProduct,
    getPreloadedProductById,
  };
}
