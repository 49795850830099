<script setup lang="ts">
import { cn } from '@/lib/utils';

const props = defineProps<{
  value: string;
  class?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'select', value: string): void;
}>();

function handleClick() {
  if (!props.disabled) {
    emit('select', props.value);
  }
}
</script>

<template>
  <div
    role="option"
    :class="cn(
      'relative flex w-full cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:text-accent data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      props.class
    )"
    :data-disabled="disabled || undefined"
    :aria-selected="false"
    @mousedown.prevent="handleClick"
    @click.prevent="handleClick"
  >
    <slot />
  </div>
</template>
