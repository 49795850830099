const storefrontAccessToken = import.meta.env.VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN;
const shopifyApiUrl = import.meta.env.VITE_SHOPIFY_STOREFRONT_API_URL;

if (!storefrontAccessToken) {
  throw new Error('VITE_SHOPIFY_STOREFRONT_ACCESS_TOKEN is not defined');
}

if (!shopifyApiUrl) {
  throw new Error('VITE_SHOPIFY_STOREFRONT_API_URL is not defined');
}

export async function shopifyFetch<T>({
  query,
  variables,
  headers = {},
  country,
}: {
  query: string;
  variables?: any;
  headers?: HeadersInit;
  country?: string;
}): Promise<{ status: number; body: T } | never> {
  try {
    const requestHeaders: Record<string, string> = {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
    };

    const result = await fetch(shopifyApiUrl, {
      method: 'POST',
      headers: {
        ...requestHeaders,
        ...headers,
      },
      body: JSON.stringify({
        query,
        variables: {
          ...variables,
          country,
        },
      }),
    });

    const body = await result.json();

    if (body.errors) {
      throw new Error(body.errors[0].message);
    }

    return {
      status: result.status,
      body,
    };
  } catch (e) {
    throw {
      error: e,
      query,
    };
  }
}
