<script setup lang="ts">
import ExternalLink from '@/components/ExternalLink.vue';
import RouterLink from '@/components/RouterLink.vue';
import Instagram from '~icons/fa6-brands/instagram';
import YouTube from '~icons/fa6-brands/youtube';
import Discord from '~icons/fa6-brands/discord';
import VerticalMarquee from '@/components/VerticalMarquee.vue';
import { Combobox } from '@/components/ui/combobox';
import { useCurrency } from '@/stores/currency';
import { onMounted, computed, ref, watch } from 'vue';

// Currency selector logic
const currencyStore = useCurrency();
const currencyOptions = ref<Array<{ value: string; label: string; description: string; searchableDescription: string }>>([]);
const isClient = ref(false);

async function initializeCurrencyStore() {
  if (!currencyStore.availableCountries.length) {
    await currencyStore.fetchAvailableCountries();
    updateCurrencyOptions();
  }
}

function updateCurrencyOptions() {
  const countries = currencyStore.availableCountries;
  if (!countries.length) return;

  // Group countries by currency code
  const currencyGroups = new Map<string, Set<string>>();

  countries.forEach((country) => {
    const currencyCode = country.currency.code;
    if (!currencyGroups.has(currencyCode)) {
      currencyGroups.set(currencyCode, new Set());
    }
    currencyGroups.get(currencyCode)?.add(country.name);
  });

  // Create options array
  const options = Array.from(currencyGroups.entries()).map(([code, countrySet]) => {
    const sortedCountries = Array.from(countrySet).sort();
    const displayCountries = sortedCountries.length > 3
      ? `${sortedCountries.slice(0, 3).join(', ')} etc.`
      : sortedCountries.join(', ');

    return {
      value: code,
      label: code,
      description: displayCountries,
      // Keep all countries in a hidden field for search
      searchableDescription: sortedCountries.join(', ')
    };
  });

  // Sort options to put USD and EUR at the top
  options.sort((a, b) => {
    if (a.value === 'USD') return -1;
    if (b.value === 'USD') return 1;
    if (a.value === 'EUR') return -1;
    if (b.value === 'EUR') return 1;
    return a.value.localeCompare(b.value);
  });

  currencyOptions.value = options;
}

watch(() => currencyStore.availableCountries, () => {
  updateCurrencyOptions();
}, { immediate: true });

onMounted(() => {
  isClient.value = true;
  if (currencyStore.availableCountries.length === 0) {
    currencyStore.fetchAvailableCountries();
  }
  initializeCurrencyStore();
});
</script>

<template>
  <footer class="footer">
    <section v-fade-in class="flex items-center justify-center">
      <VerticalMarquee class="thingy">
        <template #left>
          <h2>Premium</h2>
        </template>
        <template #right>
          <h2>safety.</h2>
          <h2>design.</h2>
          <h2>engineering.</h2>
          <h2>quality.</h2>
          <h2>construction.</h2>
          <h2>reliability.</h2>
          <h2>power.</h2>
        </template>
      </VerticalMarquee>
    </section>

    <div>
      <div class="flex items-center justify-center">
        <img src="@/assets/parsec-logo-color-horizontal.svg" alt="parsec logo" width="128" height="32" />
      </div>

      <div class="flex flex-col text-sm items-center justify-center gap-4">
        <div class="flex flex-wrap text-xl *:transition-colors gap-2">
          <ExternalLink href="https://discord.gg/gg9kFCR7m4">
            <Discord />
          </ExternalLink>
          <ExternalLink href="https://www.instagram.com/rideparsec">
            <Instagram />
          </ExternalLink>
          <ExternalLink href="https://youtu.be/8yK8U53jdT0">
            <YouTube />
          </ExternalLink>
        </div>

        <div class="flex flex-wrap items-center justify-center gap-2">
          <RouterLink to="/products/aero-pro">Aero Pro</RouterLink>
          <RouterLink to="/products/600w-fast-charger">600W Fast Charger</RouterLink>
          <a href="https://support.rideparsec.com/docs/" target="_blank" rel="noopener">Policies</a>
          <a href="https://support.rideparsec.com/docs/terms/" target="_blank" rel="noopener">Terms & Conditions</a>
          <a href="https://support.rideparsec.com/docs/about/" target="_blank" rel="noopener">About Us</a>
          <a href="https://support.rideparsec.com/" target="_blank" rel="noopener">Support</a>
          <template v-if="isClient">
            <div>
              <Combobox v-if="currencyOptions.length > 0" v-model="currencyStore.selectedCurrency"
                :disabled="currencyStore.loading" :options="currencyOptions" placeholder="Select currency..."
                class="w-[80px]" :align-offset="24" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
.footer {
  @apply flex flex-col justify-start col-span-12 gap-4 -mt-4;

  a {
    @apply text-gray-500 hover:text-gray-400 p-2;
  }

  >div {
    @apply grid w-full text-white items-center grid-rows-[1fr_auto_1fr] lg:grid-cols-[1fr_auto_1fr] lg:grid-rows-1 gap-y-6 gap-x-2;
  }
}

@media (min-width: 320px) {
  .thingy {
    font-size: 32px;
  }
}

@media (min-width: 375px) {
  .thingy {
    font-size: 40px;
  }
}

.thingy {
  @apply text-[24px] sm:text-[48px] lg:text-[100px] font-bold text-white font-chakra;

  .right {
    @apply text-[#ed1651];
  }
}
</style>
