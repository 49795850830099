<script setup lang="ts">
import { usePreloadPage } from '@/composables/usePreloadPage';
import { useShopifyPreload } from '@/composables/useShopifyPreload';
import RouterLink from '@/components/RouterLink.vue';
import { onMounted } from 'vue';

const props = defineProps<{ to: string }>();
const { preloadPage } = usePreloadPage();
const { preloadProduct } = useShopifyPreload();

// Function to handle hover event
const handleHover = () => {
  // Always preload the page
  preloadPage(props.to);

  // If this is a product link, also preload the product data
  if (props.to.startsWith('/products/')) {
    const handle = props.to.split('/products/')[1];
    if (handle) {
      preloadProduct(handle);
    }
  }
};
</script>

<template>
  <RouterLink :to="props.to" @mouseenter="handleHover" @touchstart="handleHover">
    <slot />
  </RouterLink>
</template> 