<script setup lang="ts">
import CartSidebar from '@/components/cart/CartSidebar.vue';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { watchOverlayBool } from '@/composables/overlays.ts';
import { useSidebar } from '@/composables/sidebar.ts';
import { VisuallyHidden } from 'radix-vue';

const open = useSidebar();

watchOverlayBool(open);

function onOpenChange(v: boolean) {
  open.value = v;
}
</script>

<template>
  <Sheet :open @update:open="onOpenChange">
    <SheetTrigger as-child>
      <Button variant="accent" size="iconLg" class="bg-accent hover:bg-accent-hover text-white min-w-[40px] min-h-[40px] shrink-0">
        <svg viewBox="0 -960 960 960" fill="currentColor" class="w-6 h-6">
          <path
            d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"
          />
        </svg>
      </Button>
    </SheetTrigger>
    <SheetContent class="flex flex-col gap-3 p-0">
      <SheetHeader class="p-2">
        <SheetTitle>Cart</SheetTitle>
        <VisuallyHidden>
          <SheetDescription>A list of items in your cart.</SheetDescription>
        </VisuallyHidden>
      </SheetHeader>

      <CartSidebar />
    </SheetContent>
  </Sheet>
</template>
