import type {
  MoneyV2,
  Product as ProductQuery,
  ProductVariant as ProductVariantQuery,
  SelectedOptionInput,
  ProductConnection,
} from '@/types/shopify/generated.ts';
import { $, $$, query } from '@/types/shopify/generated.ts';
import type { ReturnWriteable } from '@/types/util.ts';
import type { ResultOf } from '@graphql-typed-document-node/core';
import { getShopifyClient } from './index.ts';
import { useCurrency } from '@/stores/currency';
import { getProductPrices } from './localization';
import type { OperationResult } from '@urql/vue';

export const DEFAULT_OPTION_TITLE = 'Title';
export const DEFAULT_OPTION_VALUE_TITLE = 'Default Title';

const money = (m: MoneyV2) => [m.amount, m.currencyCode];

function _variantFields(v: ProductVariantQuery) {
  return [v.id, v.title, v.price(money), v.compareAtPrice(money), v.selectedOptions((o) => [o.name, o.value])] as const;
}

const variantFields = _variantFields as ReturnWriteable<typeof _variantFields>;

function _productFields(p: ProductQuery) {
  return [
    p.id,
    p.title,
    p.handle,
    p.descriptionHtml,
    p.encodedVariantExistence,
    p.encodedVariantAvailability,
    p.priceRange((pr) => [pr.minVariantPrice(money)]),
    p.compareAtPriceRange((pr) => [pr.minVariantPrice(money)]),
    p.images({ first: 50 }, (i) => [i.nodes((n) => [n.url({}), n.altText])]),
    p.options((o) => [o.id, o.name, o.optionValues((v) => [v.id, v.name])]),
  ] as const;
}

const productFields = _productFields as ReturnWriteable<typeof _productFields>;

const getProductByIdQuery = query((q) => [q.product({ id: $$<string, 'id'>('id') }, productFields)]);

const getProductByHandleQuery = query((q) => [q.product({ handle: $$<string, 'handle'>('handle') }, productFields)]);

const getProductsQuery = query((q) => [
  q.products({ first: $<number, 'first'>('first') }, (p: ProductConnection) => [
    // @ts-ignore: Type inference issue with GraphQL selector functions
    p.nodes(productFields),
  ]),
]);

const getVariantsByOptionsQuery = query((q) => [
  q.product({ id: $$<string, 'id'>('id') }, (p: ProductQuery) => [
    p.variantBySelectedOptions(
      { selectedOptions: $$<SelectedOptionInput[], 'selectedOptions'>('selectedOptions') },
      (v) => [
        v.id,
        v.title,
        v.price((m) => [m.amount, m.currencyCode]),
        v.compareAtPrice((m) => [m.amount, m.currencyCode]),
        v.selectedOptions((o) => [o.name, o.value]),
      ],
    ),
  ]),
]);

export type Product = NonNullable<ResultOf<typeof getProductByIdQuery>['product']> & {
  variants?: {
    nodes: Array<{
      id: string;
      title: string;
      price: {
        amount: string;
        currencyCode: string;
      };
      compareAtPrice?: {
        amount: string;
        currencyCode: string;
      };
      selectedOptions: Array<{
        name: string;
        value: string;
      }>;
    }>;
  };
};

export type ImageNode = NonNullable<Product['images']['nodes'][number]>;

export async function getProductById(id: string, countryCode?: string): Promise<Product | null> {
  const client = getShopifyClient();
  let country = countryCode;

  // Only try to use the store if we're in the browser
  if (typeof window !== 'undefined' && !country) {
    try {
      const currencyStore = useCurrency();
      country = currencyStore.selectedCountry?.code;
    } catch (error) {
      // Silently fail if store is not available
    }
  }

  // First get the base product data
  const res = await client.query<{ product: Product | null }>(getProductByIdQuery, {
    id,
  });

  if (res.error) {
    return null;
  }

  const product = res.data?.product;
  if (!product) return null;

  // Then get the prices in the correct currency
  if (country) {
    try {
      const priceData = await getProductPrices(id, country);

      if (priceData.product?.variants?.edges?.[0]?.node) {
        const variant = priceData.product.variants.edges[0].node;

        // Update all price-related fields
        if (!product.variants) {
          product.variants = { nodes: [] };
        }
        if (!product.variants.nodes[0]) {
          product.variants.nodes[0] = {
            id: 'default',
            title: 'Default Title',
            selectedOptions: [],
            price: variant.price,
          };
        } else {
          product.variants.nodes[0].price = variant.price;
        }

        // Update price ranges
        if (!product.priceRange) {
          product.priceRange = { minVariantPrice: variant.price };
        } else {
          product.priceRange.minVariantPrice = variant.price;
        }

        if (!product.compareAtPriceRange) {
          product.compareAtPriceRange = { minVariantPrice: variant.price };
        } else {
          product.compareAtPriceRange.minVariantPrice = variant.price;
        }
      }
    } catch (error) {
      // Silently fail and use default prices
    }
  }

  return product;
}

export async function getProductByHandle(handle: string, countryCode?: string): Promise<Product | null> {
  const client = getShopifyClient();
  let country = countryCode;

  // Only try to use the store if we're in the browser
  if (typeof window !== 'undefined' && !country) {
    try {
      const currencyStore = useCurrency();
      country = currencyStore.selectedCountry?.code;
    } catch (error) {
      // Silently fail if store is not available
    }
  }

  const res = await client.query<{ product: Product | null }>(getProductByHandleQuery, {
    handle,
    country,
  });

  return res.data?.product ?? null;
}

export async function getProducts(first: number, countryCode?: string): Promise<Product[] | null> {
  const client = getShopifyClient();
  let country = countryCode;

  // Only try to use the store if we're in the browser
  if (typeof window !== 'undefined' && !country) {
    try {
      const currencyStore = useCurrency();
      country = currencyStore.selectedCountry?.code;
    } catch (error) {
      // Silently fail if store is not available
    }
  }

  const res = await client.query<{ products: { nodes: Product[] } }>(getProductsQuery, {
    first,
    country,
  });

  return res.data?.products?.nodes ?? [];
}

export type Option = NonNullable<Product['options'][number]>;
export type OptionValue = Option['optionValues'][number];

export type VariantByOptions = NonNullable<
  ResultOf<typeof getVariantsByOptionsQuery>['product']
>['variantBySelectedOptions'];

const optionVariantCache = new Map<string, VariantByOptions>();

export async function getVariantByOptions(
  id: string,
  selectedOptions: SelectedOptionInput[],
): Promise<VariantByOptions | null> {
  const client = getShopifyClient();
  const currencyStore = useCurrency();
  const country = currencyStore.selectedCountry?.code;

  const optionsString = selectedOptions
    .map((o) => `${o.name}:${o.value}`)
    .sort()
    .join('_');
  const cacheKey = `${id}_${optionsString}_${country}`; // Include country in cache key

  const cached = optionVariantCache.get(cacheKey);
  if (cached) {
    return cached;
  }

  const query = `
    query getVariant($id: ID!, $selectedOptions: [SelectedOptionInput!]!, $country: CountryCode!) @inContext(country: $country) {
      product(id: $id) {
        variantBySelectedOptions(selectedOptions: $selectedOptions) {
          id
          title
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
  `;

  const res = await client.query<{ product: { variantBySelectedOptions: VariantByOptions | null } }>(query, {
    id,
    selectedOptions,
    country: country || 'US',
  });

  const variant = res.data?.product?.variantBySelectedOptions ?? null;

  if (variant) {
    optionVariantCache.set(cacheKey, variant);
  }

  return variant;
}

export function decodeVariantAvailability(encoded: string): number[][] {
  const tokenizer = /[ :,-]/g;

  let token = tokenizer.exec(encoded);
  let index = 0;

  let depth = 0;
  let rangeStart: number | null = null;

  const results: number[][] = [];
  const currentResult: number[] = [];

  while (token) {
    const operation = token[0];
    const optionValueIndex = Number.parseInt(encoded.slice(index, token.index)) || 0;

    if (rangeStart !== null) {
      while (rangeStart < optionValueIndex) {
        currentResult[depth] = rangeStart;
        results.push([...currentResult]);
        rangeStart++;
      }

      rangeStart = null;
    }

    currentResult[depth] = optionValueIndex;

    switch (operation) {
      case '-':
        rangeStart = optionValueIndex;
        break;
      case ':':
        depth++;
        break;
      default: {
        if (operation === ' ' || (operation === ',' && encoded[token.index - 1] !== ',')) {
          results.push([...currentResult]);
        }

        if (operation === ',') {
          currentResult.pop();
          depth--;
        }

        break;
      }
    }

    index = tokenizer.lastIndex;
    token = tokenizer.exec(encoded);
  }

  const lastRangeStartIndex = encoded.lastIndexOf('-');
  if (rangeStart !== null && lastRangeStartIndex > 0) {
    const finalValueIndex = Number.parseInt(encoded.substring(lastRangeStartIndex + 1)) || 0;
    while (rangeStart <= finalValueIndex) {
      currentResult[depth] = rangeStart;
      results.push([...currentResult]);
      rangeStart++;
    }
  }

  return results;
}

export function isVariantAvailable(decodedVariants: number[][], combination: number[]): boolean {
  return decodedVariants.some((variant) => {
    return variant.every((value, index) => value === combination[index]);
  });
}
