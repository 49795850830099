import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { getLocalizationData } from '@/lib/shopify/localization';

export interface Country {
  name: string;
  code: string;
  currency: {
    code: string;
    symbol: string;
  };
}

export const useCurrency = defineStore('currency', () => {
  const selectedCurrency = useLocalStorage('selectedCurrency', 'USD');
  const availableCountries = ref<Country[]>([]);
  const loading = ref(false);
  const currencyChanged = ref(0); // Counter to trigger updates

  async function fetchAvailableCountries() {
    loading.value = true;
    try {
      const data = await getLocalizationData();

      // Keep all countries but organize them by currency code
      const allCountries: Country[] = [];
      data.localization.availableCountries.forEach((country) => {
        allCountries.push({
          name: country.name,
          code: country.isoCode,
          currency: {
            code: country.currency.isoCode,
            symbol: country.currency.symbol,
          },
        });
      });

      availableCountries.value = allCountries;
    } catch (error) {
      // Fallback to USD only
      availableCountries.value = [
        {
          name: 'United States',
          code: 'US',
          currency: {
            code: 'USD',
            symbol: '$',
          },
        },
      ];
    } finally {
      loading.value = false;
    }
  }

  function setCurrency(currency: string) {
    if (currency === selectedCurrency.value) {
      return;
    }

    // Update both values in the same tick to ensure reactivity
    selectedCurrency.value = currency;
    currencyChanged.value = (currencyChanged.value || 0) + 1;
  }

  const selectedCountry = computed(() => {
    return availableCountries.value.find((country) => country.currency.code === selectedCurrency.value);
  });

  return {
    selectedCurrency,
    availableCountries,
    loading,
    selectedCountry,
    currencyChanged,
    fetchAvailableCountries,
    setCurrency,
  };
});
