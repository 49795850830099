<script setup lang="ts">
import { navigate } from 'vike/client/router';

const props = defineProps<{ to: string }>();

function handleClick(e: MouseEvent) {
  e.preventDefault();
  navigate(props.to);
}
</script>

<template>
  <a class="cursor-pointer" :href="to" @click="handleClick"><slot /></a>
</template>
