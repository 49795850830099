<script setup lang="ts">
import { useOverlayCount } from '@/composables/overlays.ts';
// import { sentryBrowserConfig } from '@/sentry.browser.config.ts';
import { useSeoMeta } from '@unhead/vue';
import { ClickScrollPlugin, OverlayScrollbars } from 'overlayscrollbars';
import { type UseOverlayScrollbarsParams, useOverlayScrollbars } from 'overlayscrollbars-vue';
import { onMounted, reactive, watch } from 'vue';
import { Head } from 'vike-vue/Head';
import { usePageContext } from 'vike-vue/usePageContext';

// sentryBrowserConfig();

const pageContext = usePageContext();
const canonicalUrl = `https://rideparsec.com${pageContext.urlPathname}`;

useSeoMeta({
  title: 'Parsec Skateboards',
  description: 'Experience the future of urban mobility with Parsec Skateboards. Innovative electric skateboards combining premium design with unmatched reliability.',
  robots: 'index, follow',
  ogUrl: canonicalUrl
});

const options = reactive({
  defer: true,
  options: {
    scrollbars: {
      theme: 'os-theme-light',
      clickScroll: true,
    },
    overflow: {
      x: 'hidden',
      y: 'scroll' as 'scroll' | 'hidden',
    },
  },
} satisfies UseOverlayScrollbarsParams);

OverlayScrollbars.plugin(ClickScrollPlugin);
const [inintialize] = useOverlayScrollbars(options);

onMounted(() => inintialize(document.body));

const overlayCount = useOverlayCount();

watch(overlayCount, (count) => {
  if (count === 0) {
    options.options.overflow.y = 'scroll';
  } else {
    options.options.overflow.y = 'hidden';
  }
});
</script>

<template>
  <Head>
    <link rel="canonical" :href="canonicalUrl" />
    <meta name="robots" content="index, follow" />
  </Head>
  <slot />
</template>
