import { usePageContext } from 'vike-vue/usePageContext';

export function usePreloadPage() {
  const pageContext = usePageContext();

  const preloadPage = (path: string) => {
    // Get the current URL without query parameters and hash
    const currentPath = window.location.pathname;

    // Don't preload if we're already on the page
    if (currentPath === path) return;

    // Create a link element for preloading
    const link = document.createElement('link');
    link.rel = 'prefetch';
    link.href = path;

    // Add the link to the document head
    document.head.appendChild(link);
  };

  return {
    preloadPage,
  };
}
