<script setup lang="ts">
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Check, ChevronsUpDown } from 'lucide-vue-next';
import { ref, computed } from 'vue';

const props = defineProps<{
  modelValue?: string;
  disabled?: boolean;
  options: Array<{ value: string; label: string; description?: string; searchableDescription?: string }>;
  placeholder?: string;
  class?: string;
  alignOffset?: number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const open = ref(false);
const search = ref('');

const filteredOptions = computed(() => {
  if (!search.value) return props.options;
  const searchLower = search.value.toLowerCase();
  return props.options.filter(
    option => 
      option.label.toLowerCase().includes(searchLower) || 
      option.value.toLowerCase().includes(searchLower) ||
      (option.searchableDescription?.toLowerCase().includes(searchLower) ?? false)
  );
});

function onSelect(value: string) {
  emit('update:modelValue', value);
  open.value = false;
  search.value = '';
}
</script>

<template>
  <Popover v-model:open="open" :class="class">
    <PopoverTrigger as-child>
      <Button
        role="combobox"
        :aria-expanded="open"
        class="combobox-button w-full justify-between !bg-[#f1f1f1] !text-black"
        :disabled="disabled"
      >
        {{ modelValue ? options.find((opt) => opt.value === modelValue)?.label : placeholder }}
        <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    </PopoverTrigger>
    <PopoverContent class="combobox-content w-full p-0" :align-offset="alignOffset">
      <Command class="bg-[#f1f1f1]">
        <CommandInput v-model="search" :placeholder="placeholder" />
        <CommandEmpty v-if="filteredOptions.length === 0">No option found.</CommandEmpty>
        <CommandList>
          <CommandGroup>
            <CommandItem
              v-for="option in filteredOptions"
              :key="option.value"
              :value="option.value"
              class="combobox-item"
              @select="onSelect"
            >
              <div class="flex flex-col w-full">
                <div class="flex items-center">
                  <Check
                    class="mr-2 h-4 w-4"
                    :class="modelValue === option.value ? 'opacity-100' : 'opacity-0'"
                  />
                  <span>{{ option.label }}</span>
                </div>
                <span v-if="option.description" class="text-sm text-gray-500 ml-6">{{ option.description }}</span>
              </div>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
</template>

<style>
.combobox-button {
  @apply border-none ring-0 shadow-none text-white;
  

  &:focus-visible {
    @apply !ring-0 !ring-offset-0;
  }
}

.combobox-content {
  @apply bg-[#f1f1f1] text-black;

  .os-viewport {
    @apply !rounded-lg;
  }

  .os-scrollbar {
    @apply !w-2;

    .os-scrollbar-track {
      @apply !bg-transparent;
    }

    .os-scrollbar-handle {
      @apply !bg-black/10 hover:!bg-black/20 !rounded-full;
    }
  }
}

.combobox-item {
  @apply hover:bg-white/50 cursor-pointer py-2;
}
</style> 