import { shopifyFetch } from './client';

export interface LocalizationData {
  localization: {
    availableCountries: Array<{
      currency: {
        isoCode: string;
        name: string;
        symbol: string;
      };
      isoCode: string;
      name: string;
      unitSystem: string;
    }>;
    country: {
      currency: {
        isoCode: string;
        name: string;
        symbol: string;
      };
      isoCode: string;
      name: string;
      unitSystem: string;
    };
  };
}

export interface ProductPriceData {
  product: {
    variants: {
      edges: Array<{
        node: {
          price: {
            amount: string;
            currencyCode: string;
          };
          compareAtPrice?: {
            amount: string;
            currencyCode: string;
          };
        };
      }>;
    };
  };
}

export async function getLocalizationData(country?: string) {
  const query = `
    query localization {
      localization {
        availableCountries {
          currency {
            isoCode
            name
            symbol
          }
          isoCode
          name
          unitSystem
        }
        country {
          currency {
            isoCode
            name
            symbol
          }
          isoCode
          name
          unitSystem
        }
      }
    }
  `;

  const { body } = await shopifyFetch<{ data: LocalizationData }>({
    query,
    country,
  });

  return body.data;
}

export async function getProductPrices(productId: string, country: string) {
  const query = `
    query productPrices($id: ID!, $country: CountryCode!) @inContext(country: $country) {
      product(id: $id) {
        variants(first: 1) {
          edges {
            node {
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  `;

  const { body } = await shopifyFetch<{ data: ProductPriceData }>({
    query,
    variables: { id: productId },
    country,
  });

  return body.data;
}
