<script setup lang="ts">
import PreloadLink from '@/components/PreloadLink.vue';
import CartButton from '@/components/cart/CartButton.vue';
import LogoHorizontalSymbol from '@/components/icons/LogoHorizontalSymbol.vue';
import { Label } from '@/components/ui/label';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { onMounted, onUnmounted, ref, useTemplateRef } from 'vue';
import Menu from '~icons/lucide/menu';

const container = useTemplateRef('header-container');
const MIN_HEADER_HEIGHT = '72px';

function onResize() {
  if (typeof window === 'undefined') return;
  container.value?.style.setProperty('--h', MIN_HEADER_HEIGHT);
  container.value?.style.setProperty('height', MIN_HEADER_HEIGHT);

  requestAnimationFrame(() => {
    // remove the height style so it can be calculated again
    container.value?.style.removeProperty('height');

    requestAnimationFrame(() => {
      container.value?.style.setProperty('--h', `${document.documentElement.scrollHeight}px`);
    });
  });
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

const navHeight = ref(0);
const nav = ref('');
</script>

<template>
  <div ref="header-container" class="header-container">
    <header>
      <div class="hidden md:block"></div>
      <PreloadLink to="/" class="md:hidden text-white hover:text-accent transition-colors">
        <LogoHorizontalSymbol height="24" />
      </PreloadLink>

      <NavigationMenu class="navigation" v-model="nav" :data-open="!!nav" @size="(v) => (navHeight = v)"
        :style="{ '--tt': navHeight }">
        <NavigationMenuList class="justify-center">
          <NavigationMenuItem>
            <NavigationMenuTrigger>Boards</NavigationMenuTrigger>
            <NavigationMenuContent>
              <NavigationMenuLink as-child>
                <PreloadLink to="/products/aero-pro">Aero Pro</PreloadLink>
              </NavigationMenuLink>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Parts</NavigationMenuTrigger>
            <NavigationMenuContent class="grid">
              <h3 class="font-medium mb-2 pb-1 border-b border-gray-300 no-hover">Accessories</h3>
              <NavigationMenuLink as-child>
                <PreloadLink to="/products/600w-fast-charger">600W Fast Charger</PreloadLink>
              </NavigationMenuLink>
              <NavigationMenuLink as-child>
                <PreloadLink to="/products/parsec-classic-wheels">Classic Wheels</PreloadLink>
              </NavigationMenuLink>
              <NavigationMenuLink as-child>
                <PreloadLink to="/products/parsec-pro-wheels">Pro Wheels</PreloadLink>
              </NavigationMenuLink>
            </NavigationMenuContent>
            <NavigationMenuContent class="grid">
              <h3 class="font-medium mb-2 pb-1 border-b border-gray-300 no-hover">Components</h3>
              <NavigationMenuLink as-child>
                <PreloadLink to="/products/st-pulley">Pulleys</PreloadLink>
              </NavigationMenuLink>
              <NavigationMenuLink as-child>
                <PreloadLink to="/products/belts">Belts</PreloadLink>
              </NavigationMenuLink>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuTrigger>Info</NavigationMenuTrigger>
            <NavigationMenuContent>
              <NavigationMenuLink as-child><a href="https://support.rideparsec.com/docs/" target="_blank"
                  rel="noopener">Policies</a></NavigationMenuLink>
              <NavigationMenuLink as-child><a href="https://support.rideparsec.com/docs/terms/" target="_blank"
                  rel="noopener">Terms & Conditions</a></NavigationMenuLink>
              <NavigationMenuLink as-child><a href="https://support.rideparsec.com/docs/about/" target="_blank"
                  rel="noopener">About Us</a></NavigationMenuLink>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <NavigationMenuItem>
            <NavigationMenuLink as-child>
              <a href="https://support.rideparsec.com/" target="_blank" rel="noopener">Support</a>
            </NavigationMenuLink>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>

      <div class="flex gap-4 items-center">
        <Popover>
          <PopoverTrigger class="md:hidden flex items-center justify-center h-12">
            <Menu class="text-white" font-size="24" />
          </PopoverTrigger>
          <PopoverContent :align-offset="24" class="md:hidden bg-[#f1f1f1] popover-content">
            <nav class="small-nav">
              <div>
                <Label>Boards</Label>
                <PreloadLink to="/products/aero-pro">Aero Pro</PreloadLink>
              </div>

              <div>
                <Label>Parts</Label>
                <div class="pl-3 mt-1 mb-2">
                  <Label class="text-sm">Accessories</Label>
                  <PreloadLink to="/products/600w-fast-charger">600W Fast Charger</PreloadLink>
                </div>
                <div class="pl-3">
                  <Label class="text-sm">Components</Label>
                  <PreloadLink to="/products/st-pulley">Pulleys</PreloadLink>
                  <PreloadLink to="/products/belts">Belts</PreloadLink>
                </div>
              </div>

              <div>
                <Label>Info</Label>
                <a href="https://support.rideparsec.com/docs/" target="_blank" rel="noopener">Policies</a>
                <a href="https://support.rideparsec.com/docs/terms/" target="_blank" rel="noopener">Terms &
                  Conditions</a>
                <a href="https://support.rideparsec.com/docs/about/" target="_blank" rel="noopener">About Us</a>
              </div>

              <div>
                <Label>Support</Label>
                <a href="https://support.rideparsec.com/" target="_blank" rel="noopener">Support</a>
              </div>
            </nav>
          </PopoverContent>
        </Popover>

        <CartButton />
      </div>
    </header>
  </div>
</template>

<style>
.header-container {
  --top-gap: 24px;
  @apply absolute left-0 right-0 top-[var(--top-gap)] pointer-events-none md:h-[calc(var(--h)_-_var(--top-gap))];
}

header {
  @apply z-50 md:sticky top-0 grid grid-cols-[1fr_auto_auto] md:grid-cols-[120px_1fr_120px] w-full h-12 px-6 md:px-12 transition-[top] items-center;

  >* {
    @apply pointer-events-auto;
  }
}

/* Last column in desktop - cart button container */
header> :last-child {
  @apply md:justify-self-end;
}

.navigation {
  @apply hidden md:block w-full;
  justify-self: center;

  .nav-viewport-container {
    top: calc(100% - 25px);
  }

  &[data-open='true'] {
    ul::before {
      bottom: calc(-1px * var(--tt) + 1.5rem + 1px);
    }
  }

  .nav-trigger,
  .nav-content>*:not(h3),
  .nav-list>li>a {
    @apply px-4 py-2 hover:bg-black/5 rounded-[10px];
  }

  /* Navigation headings */
  h3.no-hover {
    @apply pointer-events-none;
  }

  ul {
    @apply flex relative p-4 mx-auto;

    &::before {
      content: '';
      @apply absolute left-0 right-0 top-0 bottom-0 -z-10;

      backdrop-filter: blur(18px) saturate(1.5);
      clip-path: inset(12px 12px 12px 12px round 12px);
      transition: background 0.4s;
      will-change: opacity;
      background: rgba(255, 255, 255, 0.6);
    }

    li {
      @apply whitespace-nowrap;
    }
  }
}

.small-nav {
  @apply flex flex-col;

  >div {
    @apply flex flex-col py-2;

    >label {
      @apply font-semibold text-lg mb-2 pb-1 border-b border-gray-300 pointer-events-none;
    }

    >a {
      @apply py-1;

      &:hover {
        @apply text-accent;
      }

      &:first-child {
        @apply pt-0;
      }

      &:last-child {
        @apply pb-0;
      }
    }
  }
}

/* Add styles for hamburger menu dropdown */
:deep(.popover-content) {
  @apply !bg-[#f1f1f1];
}
</style>
